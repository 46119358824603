const serviceApp = process.env.VUE_APP_REPORTS_SERVICE;

export default {
  methods: {
    async getPerformanceResume(date) {
      let dateRequest = '/';

      if (date) {
        dateRequest = `?date=${date}`;
      }

      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/v2/performance${dateRequest}`);
    },

    async getSmartDashboardResume(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/summary${filter}`);
    },

    async getCampaignCommissionFull(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/commissionPerCampaign${filter}`);
    },

    async getChannelCommissionFull(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/commissionPerChannel${filter}`);
    },

    async getCommissionPerMonth(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/commissionPerMonth${filter}`);
    },

    async getCommissionByStatus(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/transactionsPerStatus${filter}`);
    },

    async getCommissionFullDaily(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/commissionPerDay${filter}`);
    },

    async getCampaignVisionSmartDashboardResume(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/campaign/summary${filter}`);
    },

    async getCampaignChannelResume(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/campaign/channels${filter}`);
    },

    async getCampaignCommissionByDay(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/campaign/transactionsByDay${filter}`);
    },

    async getCampaignCommissionByStatus(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/campaign/transactionsByStatus${filter}`);
    },

    async getCampaignTransactionsByMonth(filter) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/dashboard/campaign/transactionsByMonth${filter}`);
    }
  }
};
